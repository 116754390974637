import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Routes from "./Routes";
import colors from "./colors";
import "./App.scss";

function App(){
    const theme = createTheme({
        palette: {
            primary: {
                light: colors().primaryLight,
                main: colors().primary,
            },
            secondary: {
                main: colors().accent,
            },
        },
    });
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <div id="app" className="main-scr select-none overflow-hidden">
                    <div className="flex w-screen h-screen flex-shrink-0">
                        <div className="route flex flex-col flex-grow">
                            <div className="flex flex-1 flex-grow overflow-auto">
                                <Routes/>
                            </div>
                        </div>
                    </div>
                </div>
            </Router>
        </ThemeProvider>
    );
}

export default App;
