import React, { Suspense } from "react";
import { Routes as Switch, Route } from "react-router-dom";
import Spinner from "./views/components/Spinner";
import NotFound from "./views/pages/NotFound";

const Home = React.lazy(() => import("./views/pages/Home"));
const Terms = React.lazy(() => import("./views/pages/Terms"));
const Privacy = React.lazy(() => import("./views/pages/Privacy"));

export default function Routes(){
    return (
        <Switch>
            <Route path="/" element={<LazyLoader component={Home}/>}/>
            <Route path="/terms" element={<LazyLoader component={Terms}/>}/>
            <Route path="/privacy" element={<LazyLoader component={Privacy}/>}/>
            <Route path="*" element={<NotFound/>}/>
        </Switch>
    );
}

interface LazyLoaderProps {
    component: React.LazyExoticComponent<() => JSX.Element>,
}

const LazyLoader = ({ component }: LazyLoaderProps) => {
    const Component = component;
    return (
        <Suspense
            fallback={(
                <div className="flex flex-1 items-center justify-center">
                    <Spinner color="#666"/>
                </div>
            )}
        >
            <Component/>
        </Suspense>
    )
}
