import React from "react";
import * as ReactDOM from "react-dom";
import { ToastContainer, cssTransition } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers";
import AdapterDateFns from "@mui/x-date-pickers/AdapterDateFns";
import App from "./App";

import "@fontsource/manrope/200.css";
import "@fontsource/manrope/300.css";
import "@fontsource/manrope/400.css";
import "@fontsource/manrope/500.css";
import "@fontsource/manrope/600.css";
import "@fontsource/manrope/700.css";
import "@fontsource/manrope/800.css";
import "animate.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";

export const bounce = cssTransition({
    enter: "animate__animated animate__fadeInRight",
    exit: "animate__animated animate__fadeOut",
    collapseDuration: 100,
    collapse: false,
});

ReactDOM.render(
    <>
        <App/>
        <ToastContainer
            icon={false}
            autoClose={10000}
            transition={bounce}
            hideProgressBar={true}
            toastClassName="dark-toast"
        />
    </>,
    document.getElementById("root"),
);
