import React from "react";
import "./Spinner.scss";

interface Props {
    color: string
}

const spinnerElement: any[] = [];

for (let i = 0; i < 12; i++){
    spinnerElement.push({});
}

export default function Spinner(props: Props) {
    return (
        <div className="lds-css ng-scope">
            <div
                className="lds-spinner"
                style={{
                    width: "100%",
                    height: "100%"
                }}
            >
                {
                    spinnerElement.map((item, i) =>
                        <div
                            key={`spinner-div-${i}`}
                            style={{
                                backgroundColor: props.color
                            }}
                        />
                    )
                }
            </div>
        </div>
    );
}

Spinner.defaultProps = {
    color: "#fff"
}
