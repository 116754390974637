import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function NotFound(){
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        console.log(location);
        if (location.pathname !== "/404"){
            navigate("/404");
        }
    });
    return (
        <div className="flex flex-1 items-center justify-center">
            <div className="">
                <div className="text-9xl text-black/[.3]">
                    404
                </div>
            </div>
        </div>
    );
}
