interface ColorInterface {
    primary: string,
    primaryLight: string,
    accent: string,
}

export default function colors(): ColorInterface {
    if (process.env.NODE_ENV === "test"){
        return {
            primary: "#1565c0",
            primaryLight: "#bbdefb",
            accent: "#03c2fd",
        }
    }
    const style = getComputedStyle(document.body);
    const getValue = (key: string) => {
        const value = style.getPropertyValue(key).replace(/\s/g,"");
        if (value == null){
            return "#1565c0";
        }
        return value;
    };
    return {
        primary: getValue("--primary"),
        primaryLight: getValue("--primary-light"),
        accent: getValue("--accent"),
    }
}
